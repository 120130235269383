import React, { useEffect, useState, useContext } from 'react';
import { Container, TextField, Button, FormControl, FormControlLabel, FormLabel, Radio, RadioGroup, InputLabel, Select, MenuItem } from '@material-ui/core';
import DriveFolderUploadOutlinedIcon from '@mui/icons-material/DriveFolderUploadOutlined';
import { setDoc, doc } from "firebase/firestore"; 
import { db, storage } from "../../firebase";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import placeimage from '../../assets/person.png';
import { AuthContext } from '../../context/AuthContext';

export const Address = ({formData, setForm, navigation}) => {

    let { driverLicense, insurance, driverLicenseCat, county, subCounty, ward, driverType, location} = formData;

    const [photo, setPhoto] = useState("");

    const [idfront, setIdFront] = useState("");

    const [idback, setIdBack] = useState("");

    const [photoerror, setPhotoError] = useState("");

    const [idfronterror, setIdFrontError] = useState("");

    const [idbackerror, setIdBackError] = useState("");

    let [flag, setFlag] = useState(false);

    let [insflag, setInsFlag] = useState(false);

    const [per, setPerc] = useState(null);

    const {currentUser} = useContext(AuthContext); 

    const licf = localStorage.getItem('lic');
    //   console.log(lic);

      // if (licf === "Yes") setFlag(true);

    const driverstype = [
      {
        id:1,
        name: 'Cargo'
      },
      {
        id:2,
        name: 'Passenger'
      },
    ];


    const counties = [
      {
        id:1,
        name: 'Mombasa'
      },
      {
        id:2,
        name: 'Kwale'
      },
      {
        id:3,
        name: 'Kilifi'
      },
      {
        id:4,
        name: 'Tana River'
      },
      {
        id:5,
        name: 'Lamu'
      },
      {
        id:6,
        name: 'Taita Taveta'
      },
      {
        id:7,
        name: 'Garissa'
      },
      {
        id:8,
        name: 'Wajir'
      },
      {
        id:9,
        name: 'Mandera'
      },
      {
        id:10,
        name: 'Marsabit'
      },
      {
        id:11,
        name: 'Isiolo'
      },
      {
        id:12,
        name: 'Meru'
      },
      {
        id:13,
        name: 'Tharka-Nithi'
      },
      {
        id:14,
        name: 'Embu'
      },
      {
        id:15,
        name: 'Kitui'
      },
      {
        id:16,
        name: 'Machakos'
      },
      {
        id:17,
        name: 'Makueni'
      },
      {
        id:18,
        name: 'Nyandarua'
      },
      {
        id:19,
        name: 'Nyeri'
      },
      {
        id:20,
        name: 'Kirinyaga'
      },
      {
        id:21,
        name: 'Murang\'a'
      },
      {
        id:22,
        name: 'Kiambu'
      },
      {
        id:23,
        name: 'Turkana'
      },
      {
        id:24,
        name: 'West Pokot'
      },
      {
        id:25,
        name: 'Samburu'
      },
      {
        id:26,
        name: 'Trans Nzoia'
      },
      {
        id:27,
        name: 'Uasin Gishu'
      },
      {
        id:28,
        name: 'Elgeyo Marakwet'
      },
      {
        id:29,
        name: 'Nandi'
      },
      {
        id:30,
        name: 'Baringo'
      },
      {
        id:31,
        name: 'Laikipia'
      },
      {
        id:32,
        name: 'Nakuru'
      },
      {
        id:33,
        name: 'Narok'
      },
      {
        id:34,
        name: 'Kajiado'
      },
      {
        id:35,
        name: 'Kericho'
      },
      {
        id:36,
        name: 'Bomet'
      },
      {
        id:37,
        name: 'Kakamega'
      },
      {
        id:38,
        name: 'Vihiga'
      },
      {
        id:39,
        name: 'Bungoma'
      },
      {
        id:40,
        name: 'Busia'
      },
      {
        id:41,
        name: 'Siaya'
      },
      {
        id:42,
        name: 'Kisumu'
      },
      {
        id:43,
        name: 'Homabay'
      },
      {
        id:44,
        name: 'Migori'
      },
      {
        id:45,
        name: 'Kisii'
      },
      {
        id:46,
        name: 'Nyamira'
      },
      {
        id:47,
        name: 'Nairobi City'
      },
    ];

    const licenseCats = [
      {
        id:1,
        name: 'A3'
      },
      {
        id:2,
        name:'FG'
      }
    ];

    const insuranceslct = [
      {
        id:1,
        name: 'Personal Accident'
      },
      {
        id:2,
        name:'Motor Insurance'
      }
    ];

    const lic = localStorage.getItem('lic');

    const handleGenderChange = (e) => {
      const lic = localStorage.getItem('lic');

      if (e.target.value === "Yes") {
        setFlag(true);
        localStorage.setItem("lic", "Yes");

        const lic = localStorage.getItem('lic');
        if (lic === "Yes") {
          // console.log('first')
          setFlag(true);
        }
      }
      else if (e.target.value === "No") {
        setFlag(false);
        let driverLicense = "No";
        // console.log(driverLicense);
        localStorage.setItem("lic", "No");
      }
      else if (lic === "Yes") {
        setFlag(true);
      }

    }

    const [data, setData] = useState("");

    const handleInsurance = (e) => {
      const lic = localStorage.getItem('lic');

      if (e.target.value === "Yes") {
        setInsFlag(true);
        localStorage.setItem("ins", "Yes");

        const lic = localStorage.getItem('lic');
        if (lic === "Yes") {
          // console.log('first')
          setInsFlag(true);
        }
      }
      else if (e.target.value === "No") {
        setInsFlag(false);
        // let driverLicense = "No";
        // console.log(driverLicense);
        localStorage.setItem("ins", "No");
        // setData((prev)=> ({...prev, insurance: "No"}));
      }
      else if (lic === "Yes") {
        setInsFlag(true);
      }

    }

    const lics = localStorage.getItem('lic');

    let nolics;

    if (lics === "No") driverLicense = lics;

    if (lics === "Yes") flag = true;
    

    // Image Upload Logic and Functions
    const types = ['image/png', 'image/jpeg', 'image/jpg'];

    const photoHandler = (e) => {

      let selected = e.target.files[0];

      if (selected && types.includes(selected.type)) {
          setPhoto(selected);
          setPhotoError("");
      }
      else {
          setPhoto(null);
          setPhotoError("Please select an image file (png or jpeg or jpg)");
      }
    }


    const idfrontHandler = (e) => {

      let selected = e.target.files[0];

      if (selected && types.includes(selected.type)) {
          setIdFront(selected);
          setIdFrontError("");
      }
      else {
          setIdFront(null);
          setIdFrontError("Please select an image file (png or jpeg or jpg)");
      }
    }

    const idbackHandler = (e) => {

      let selected = e.target.files[0];

      if (selected && types.includes(selected.type)) {
          setIdBack(selected);
          setIdBackError("");
      }
      else {
          setIdBack(null);
          setIdBackError("Please select an image file (png or jpeg or jpg)");
      }
    }

    // const [imgp, setImgP] = useState('');

    useEffect(() => {
      const uploadFile = () => {
        const photoname = new Date().getTime() + photo.name;
        // console.log(name);

        const storageRef = ref(storage);

        const driversRef = ref(storageRef, "drivers"); //file.name - substitute with drivers

        const spaceRef = ref(driversRef, photoname);

        const uploadTask = uploadBytesResumable(spaceRef, photo);
  
        uploadTask.on('state_changed', 
          (snapshot) => {
            const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            // console.log('Upload is ' + progress + '% done');
            setPerc(progress);
            switch (snapshot.state) {
              case 'paused':
                // console.log('Upload is paused');
                break;
              case 'running':
                // console.log('Upload is running');
                break;
              default:
                break;
            }
          }, 
          (error) => {
            // Handle unsuccessful uploads
            // console.log(error);
          }, 
          () => {
            // Handle successful uploads on complete
            // For instance, get the download URL: https://firebasestorage.googleapis.com/...
            getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
              // console.log('File available at', downloadURL);

              let imageURL = downloadURL;

              // console.log(imageURL);
              localStorage.setItem("img", imageURL);

              // upload image to cloud firestore
              // setDoc(doc(db, "drivers", currentUser.uid), {
              //   image: imageURL,
              // });
              // setImgP((prev)=>({...prev, imgp:downloadURL}));
            });
            
            
          }
        );
        
      };
      photo && uploadFile();
    }, [photo]);

    // console.log(imgp);

    // img = imgp;

    useEffect(() => {
      const uploadIdBack = () => {
        // const name = new Date().getTime() + file.name;
        // console.log(name);
        const storageRef = ref(storage);

        const driversRef = ref(storageRef, "drivers"); //file.name - substitute with drivers

        const spaceRef = ref(driversRef, idback.name);

        const uploadTask = uploadBytesResumable(spaceRef, idback);
  
        uploadTask.on('state_changed', 
          (snapshot) => {
            const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            // console.log('Upload is ' + progress + '% done');
            setPerc(progress);
            switch (snapshot.state) {
              case 'paused':
                // console.log('Upload is paused');
                break;
              case 'running':
                // console.log('Upload is running');
                break;
              default:
                break;
            }
          }, 
          (error) => {
            // Handle unsuccessful uploads
            // console.log(error);
          }, 
          () => {
            // Handle successful uploads on complete
            // For instance, get the download URL: https://firebasestorage.googleapis.com/...
            getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
              // console.log('File available at', downloadURL);

              let idbackURL = downloadURL;

              localStorage.setItem("idBack", idbackURL);

              // upload image to cloud firestore
              // setDoc(doc(db, "drivers", currentUser.uid), {
              //   nationalidback: idbackURL,
              // }, {merge: true});
            });
            
            
          }
        );
      };
      idback && uploadIdBack();
    }, [idback]);


    useEffect(() => {
      const uploadIdFront = () => {
        const idfrontname = new Date().getTime() + idfront.name;
        // console.log(name);
        const storageRef = ref(storage);

        const driversRef = ref(storageRef, "drivers"); //file.name - substitute with drivers

        const spaceRef = ref(driversRef, idfrontname);

        const uploadTask = uploadBytesResumable(spaceRef, idfront);
  
        uploadTask.on('state_changed', 
          (snapshot) => {
            const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            // console.log('Upload is ' + progress + '% done');
            setPerc(progress);
            switch (snapshot.state) {
              case 'paused':
                // console.log('Upload is paused');
                break;
              case 'running':
                // console.log('Upload is running');
                break;
              default:
                break;
            }
          }, 
          (error) => {
            // Handle unsuccessful uploads
            // console.log(error);
          }, 
          () => {
            // Handle successful uploads on complete
            // For instance, get the download URL: https://firebasestorage.googleapis.com/...
            getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
              // console.log('File available at', downloadURL);

              let idfrontURL = downloadURL;

              localStorage.setItem("idFront", idfrontURL);

              // upload image to cloud firestore
              // setDoc(doc(db, "drivers", currentUser.uid), {
              //   nationalidfront: idfrontURL,
              // }, {merge: true});
            });
            
            
          }
        );
      };
      idfront && uploadIdFront();
    }, [idfront]);

    const insc = localStorage.getItem('ins');

    let noins;

    if (insc === "No") insurance = insc;

    if (insc === "Yes") insflag = true;

    console.log(insurance)

    const [validerror, setValidError] = useState("");

    // get passport image
    const img = localStorage.getItem('img');
    // get idfront image url
    const idFront = localStorage.getItem('idFront');
    // get idback url
    const idBack = localStorage.getItem("idBack");

    const validate = () => {
      if (driverLicense === "" || insurance === "" || county === "" || subCounty === "" || ward === "" || driverType === "" || location === "" || img == null || idFront == null || idBack == null) {
        setValidError("Please fill in all the required fields");
      }
      else {
        navigation.next();
      }
    }

  return (
    <>
    <br></br>
    <div className='mt-1'>
    <Container maxWidth="xs">
    <h3>Step 2/2</h3><br></br>
    <div className='flex flex-row'>
      <img src={photo ? URL.createObjectURL(photo) : img ? img : placeimage} alt="" className='upload-image'/>
      <label htmlFor="photo" className='ml-10 mt-4'>Passport size photo: <DriveFolderUploadOutlinedIcon className="icon"/></label>

      <input type="file" id="photo" onChange={photoHandler} style={{display: "none"}}/>
    </div>
    {photoerror && <div id='validation-error'>{photoerror}</div>}
    <br></br>
    <h1 className='mb-2'>Please upload front and back photos of your National ID</h1>
    <div className='flex flex-row'>
      <img src={idfront ? URL.createObjectURL(idfront) : idFront ? idFront : placeimage} alt="" className='upload-id'/>
      <label htmlFor="idfront" className='ml-10 mt-4'>Front side: <DriveFolderUploadOutlinedIcon className="icon"/></label>

      <input type="file" id="idfront" onChange={idfrontHandler} style={{display: "none"}}/>
    </div>
    {idfronterror && <div id='validation-error'>{idfronterror}</div>}

    <br></br>
    <div className='flex flex-row'>
      <img src={idback ? URL.createObjectURL(idback) : idBack ? idBack : placeimage} alt="" className='upload-id'/>
      <label htmlFor="idback" className='ml-10 mt-4'>Back side: <DriveFolderUploadOutlinedIcon className="icon"/></label>

      <input type="file" id="idback" onChange={idbackHandler} style={{display: "none"}}/>
    </div>   
    {/* (e) => setIdBack(e.target.files[0]) */}
    {idbackerror && <div id='validation-error'>{idbackerror}</div>}

    <br></br>
    <FormControl fullWidth>
      <InputLabel id="demo-simple-select-label" className='bold-dark-text'>Driver Type:</InputLabel>
      <Select
        labelId="demo-simple-select-label"
        id="demo-simple-select"
        value={driverType}
        label="Passenger"
        name="driverType"
        onChange={setForm}
        required={true}
      >
        {driverstype.map((drivertype) => (
        <MenuItem
          key={drivertype.id}
          value={drivertype.name}
        >{drivertype.name}</MenuItem>))}
      </Select>
    </FormControl><br></br><br></br>
    <FormControl>
      <FormLabel id="demo-controlled-radio-buttons-group" style={{color: "#060606"}}>Do you have a driver's license?</FormLabel>
      <RadioGroup
      row
        aria-labelledby="demo-controlled-radio-buttons-group"
        name="driverLicense"  //removed - controlled-radio-buttons-group 
        // value={driverLicense}
        onChange={handleGenderChange}
        required={true}
      >
        <FormControlLabel value="Yes" control={<Radio />} label="Yes" />
        <FormControlLabel value="No" control={<Radio />} label="No" />
      </RadioGroup>
    </FormControl><br></br><br></br>
    <div className='flex-row' style={{display: flag ? "flex" : "none"}}>
      <FormControl style={{width: "200px", marginRight: "20px"}}>
        <InputLabel id="demo-simple-select-label" className='font-bold text-black'>License Category</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={driverLicenseCat}
          defaultValue="county"
          name="driverLicenseCat"
          onChange={setForm}
          required={true}
        >
          {licenseCats.map((lic) => (
          <MenuItem
            key={lic.id}
            value={lic.name}
          >{lic.name}</MenuItem>))}
        </Select>
      </FormControl>
      <TextField
          label="License Number"
          name="driverLicense"
          onChange={setForm}
          required={true}
          //  inputProps={{ maxLength:8 }}
          inputProps={{ style:{ textTransform: "uppercase"} }}
          value={driverLicense} margin="normal" variant='outlined' autoComplete='off'
          // style={{display: flag ? "block" : "none"}}
      /><br></br>
    </div>

    <FormControl>
      <FormLabel id="demo-controlled-radio-buttons-group" style={{color: "#060606"}}>Do you have insurance?</FormLabel>
      <RadioGroup
      row
        aria-labelledby="demo-controlled-radio-buttons-group"
        name="insurance"  //removed - controlled-radio-buttons-group 
        // value={driverLicense}
        onChange={handleInsurance}
        required={true}
      >
        <FormControlLabel value="Yes" control={<Radio />} label="Yes" />
        <FormControlLabel value="No" control={<Radio />} label="No" />
      </RadioGroup>
    </FormControl><br></br>
    
    <div style={{display: insflag ? "flex" : "none"}}>
    <FormControl fullWidth>
      <InputLabel id="demo-simple-select-label" className='font-bold text-black'>Insurance</InputLabel>
      <Select
        labelId="demo-simple-select-label"
        id="demo-simple-select"
        value={insurance}
        defaultValue="insurance"
        name="insurance"
        onChange={setForm}
        required={true}
      >
        {insuranceslct.map((inselect) => (
        <MenuItem
          key={inselect.id}
          value={inselect.name}
        >{inselect.name}</MenuItem>))}
      </Select>
    </FormControl>
    </div><br></br>

    <FormControl fullWidth>
      <InputLabel id="demo-simple-select-label" className='font-bold text-black'>County*</InputLabel>
      <Select
        labelId="demo-simple-select-label"
        id="demo-simple-select"
        value={county}
        defaultValue="county"
        name="county"
        onChange={setForm}
        required={true}
      >
        {counties.map((count) => (
        <MenuItem
          key={count.id}
          value={count.name}
        >{count.name}</MenuItem>))}
      </Select>
    </FormControl><br></br><br></br>
    <TextField
     label="SubCounty"
     name="subCounty"
     value={subCounty} 
     onChange={setForm}
     required={true}
     margin="normal" variant='outlined' autoComplete='off' fullWidth
    />
    <TextField
     label="Ward"
     name="ward"
     value={ward} 
     onChange={setForm}
     required={true}
     margin="normal" variant='outlined' autoComplete='off' fullWidth
    />
    <TextField
     label="Location"
     name="location"
     value={location} 
     onChange={setForm}
     required={true}
     margin="normal" variant='outlined' autoComplete='off' fullWidth
    />
    {validerror && <p id='validation-error'>{validerror}</p>}
    <div style={{marginTop: '1rem', paddingBottom: "5rem"}}>
        <Button color="secondary"  disabled={per !== null && per < 100} variant="contained" style={{marginRight: '1rem'}} onClick={() => navigation.previous()}>Back</Button>
        <Button color="primary" disabled={per !== null && per < 100} variant="contained" style={{backgroundColor: "#82ad37"}} onClick={validate}>Next</Button>
    </div>
   
</Container>
</div>
</>
  )
}
