import React, { useContext } from 'react';
import { useForm, useStep } from 'react-hooks-helper';
import { Names } from '../../components/stepform/Names';
import { Address } from '../../components/stepform/Address';
import { Contact } from '../../components/stepform/Contact';
import { Review } from '../../components/stepform/Review';
import { Submit } from '../../components/stepform/Submit';
import { AuthContext } from '../../context/AuthContext';


const defaultData = {
    driverfirstName: '',
    driverlastName: '',
    driverGender : '',
    driverPhone: '',
    nationalID: '',
    driverYearOfBirth: '',
    group: '',
    groupname: '',
    // driver: '',
    // img: '',
    driverType: '',
    insurance: '',
    driverLicense: '',
    driverLicenseCat: '',
    county: '',
    subCounty: '',
    ward: '',
    location: '',
    tuktukPlateNo: '',
    logbook: '',
    stage: '',
    // sacco: '',
    tuktukOwner: '',
    tuktukOwnerPhoneNumber: '',
};

const steps = [
    {id: 'names'},
    {id: 'address'},
    {id: 'contact'},
    {id: 'review'},
    {id: 'submit'},
];
    

export const MultiStepForm = () => {
    const [formData, setForm] = useForm(defaultData);
    const {step, navigation} = useStep({
        steps,
        initialStep: 0,
    });

    const props = {formData, setForm, navigation};

    switch (step.id) {
        case 'names':
            return <Names { ...props}/>;
        case 'address':
            return <Address { ...props}/>;
        case 'contact':
            return <Contact { ...props}/>;  
        case 'review':
            return <Review { ...props}/>;  
        case 'submit':
            return <Submit { ...props}/>;
        default:
            break;          
    }

  return (
    <div><h1>MultiStepForm</h1></div>
  )
}
