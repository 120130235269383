import React from 'react';
import Container from '@material-ui/core/Container';
import Button from '@material-ui/core/Button';
import {Link, useNavigate} from "react-router-dom";
import { useContext, useState } from "react";
import { signOut } from "firebase/auth";
import { auth } from "../../firebase.js";
import { AuthContext } from "../../context/AuthContext";

export const Submit = () => {

  const navigate = useNavigate();

  const [error, setError] = useState(false);

  // const {state} = useContext(AuthReducer);
  const {dispatch} = useContext(AuthContext);

  const goToHome = () => {
    navigate("/home");
  }

  const handleLogOut = (e) => {
    e.preventDefault();

    signOut(auth).then(() => {
    // Sign-out successful.
        dispatch({
            type: "LOGOUT",
          })
        // localStorage.removeItem("user");
        window.localStorage.clear();
        navigate("/")
    }).catch((error) => {
    // An error happened.
    setError(true)
    console.log(error);
    });

  }

  return (
    <Container maxWidth="sm" style={{marginTop: "4rem"}}>
        <h1>Karibu Driver</h1>
        <h3>Thank you, your registration was successfull</h3>
        {/* <h3>We will be in touch</h3> */}
        <Button color="primary" variant='contained' style={{marginTop: '1.5rem'}} onClick={handleLogOut}>
            EXIT
        </Button>
        {/* <Button color="primary" variant='contained' style={{marginTop: '1.5rem'}} onClick={goToHome}>
            Go to homepage
        </Button> */}
    </Container>
  )
}
