import React from 'react';
import {BsPersonFill} from 'react-icons/bs';
import {FiSearch} from 'react-icons/fi';
import {BsQuestionLg} from 'react-icons/bs';
import {MdQuiz} from 'react-icons/md';
import {FaPhoneAlt} from 'react-icons/fa';
import {MdOutlineLogout} from 'react-icons/md';
import {Link, useNavigate} from "react-router-dom";
import { useContext, useState } from "react";
import { signOut } from "firebase/auth";
import { auth } from "../../firebase.js";
import { AuthContext } from "../../context/AuthContext";

const ListSettings = () => {

    const navigate = useNavigate();

    const [error, setError] = useState(false);

    // const {state} = useContext(AuthReducer);
    const {dispatch} = useContext(AuthContext);

    const goToProfile = () => {
        navigate("/home/settings/profile");
    }


    const handleLogOut = (e) => {
        e.preventDefault();
    
        signOut(auth).then(() => {
        // Sign-out successful.
            dispatch({
                type: "LOGOUT",
              })
            // localStorage.removeItem("user"); 
            navigate("/")
        }).catch((error) => {
        // An error happened.
        setError(true)
        console.log(error);
        });
    
      }

    const links = [
        {
            id: 1,
            child: (
                <>
                    <BsPersonFill size={30}/> Profile
                </>
            ),
            click: goToProfile,
        },
        {
            id: 2,
            child: (
                <>
                    <FiSearch size={30}/> Transactions
                </>
            ),
            // click: "https://www.twitter.com/SamRasugu/",
        },
        {
            id: 3,
            child: (
                <>
                    <BsQuestionLg size={30}/> About Us
                </>
            ),
            // click: "https://github.com/samrasugu",
        },
        {
            id: 4,
            child: (
                <>
                    <MdQuiz size={30}/> FAQ
                </>
            ),
            // click: "mailto:mokuasamr@gmail.com",
        },
        {
            id: 5,
            child: (
                <>
                    <FaPhoneAlt size={30}/> Support
                </>
            ),
            // click: '',
        },
        {
            id: 6,
            child: (
                <>
                    <MdOutlineLogout size={30}/> LogOut
                </>
            ),
            click: handleLogOut,
        }
    ];
  return (
    <div className='max-w-screen-lg p-4 py-10 mx-auto flex flex-col justify-center w-full h-full lg:ml-60'>
        <ul>
            {links.map(({id, child, click, style}) => (
                <li key={id} className={'flex justify-between items-left w-60 h-14 px-4 duration-300 text-black' + ' ' + style}>
                    <a className='flex justify-between items-center cursor-pointer w-full text-black' onClick={click}>
                        {child}
                    </a>
                </li>
            ))}

        </ul>
        
    </div>
  )
}

export default ListSettings