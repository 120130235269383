import {React, useContext} from 'react';
import { Container } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetail from '@material-ui/core/AccordionDetails';
import ListItemText from '@material-ui/core/ListItemText';
import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useNavigate } from "react-router-dom";
import { doc, serverTimestamp, setDoc } from "firebase/firestore"; 
import { db } from "../../firebase";
import { AuthContext } from '../../context/AuthContext';
import axios from 'axios';

export const Review = ({formData, navigation}) => {
    const {go} = navigation;

    const navigate = useNavigate();

    const {currentUser} = useContext(AuthContext);

    // id = currentUser.uid;

    let {
        // id,
        driverfirstName,
        driverlastName,
        driverGender,
        driverYearOfBirth,
        group,
        groupname,
        nationalID,
        driverType,
        driverLicense,
        driverLicenseCat,
        insurance,
        county,
        subCounty,
        ward,
        location,
        tuktukPlateNo,
        logbook,
        stage,
        // sacco,
        tuktukOwner,
        tuktukOwnerPhoneNumber
    } = formData;

    // get passport image
    const img = localStorage.getItem('img');
    // get idfront image url
    const idFront = localStorage.getItem('idFront');
    // get idback url
    const idBack = localStorage.getItem("idBack");

    // logic for checking if license is empty from formData and set to "No" from local storage: maybe not necessary
    const lic = localStorage.getItem('lic');
    // console.log(lic);

    let nolic;

    let noliccat;

    if (lic === "No" && driverLicense === "") nolic = lic;

    if (lic === "No" && driverLicense === "") noliccat = "";

    // console.log(nolic);

    const insc = localStorage.getItem('ins');

    let noins;

    if (insc === "No") noins = insc;

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
          await setDoc(doc(db, "drivers", currentUser.uid), {
            ...formData,
            driverLicense: driverLicense ? driverLicense : nolic,
            insurance: insurance ? insurance : noins,
            driverPhone: currentUser.phoneNumber,
            image: img,
            nationalidback: idBack,
            nationalidfront: idFront,
            timeStamp: serverTimestamp()
          }, { merge: true });

        // const id = currentUser.uid;
        // const userData = {
        //     id,
        //     driverfirstName,
        //     driverlastName,
        //     driverPhone: currentUser.phoneNumber,
        //     driverGender,
        //     driverYearOfBirth,
        //     img,
        //     idFront,
        //     idBack,
        //     group,
        //     groupname,
        //     nationalID,
        //     driverType,
        //     driverLicense,
        //     driverLicenseCat,
        //     insurance,
        //     county,
        //     subCounty,
        //     ward,
        //     location,
        //     tuktukPlateNo,
        //     logbook,
        //     stage,
        //     // sacco,
        //     tuktukOwner,
        //     tuktukOwnerPhoneNumber
        // }
        // console.log(userData);
        // await axios.post("http://localhost:8800/drivers", userData);

        // clear localstorage
        window.localStorage.clear();

        navigation.next();

        } catch (err) {
          console.log(err);
        }       
    };

    if (driverfirstName === tuktukOwner) {
        console.log("Apollo")
    }

  return (
    <>
    <br></br>
    <br></br>
    <div className='mt-8'>
    <Container maxWidth="sm">
        <h3>Review</h3>
        <RenderAccordion summary="Names" go={go} details={[
            {'First Name': driverfirstName},
            {'Last Name': driverlastName},
            {'Gender': driverGender},
            {'Date of Birth': driverYearOfBirth},
            {'National ID': nationalID},
            {'Group': group},
            {'Group Name': groupname},
            {'Insurance': insurance ? insurance : noins}
        ]}/>
        <RenderAccordion summary="Address" go={go} details={[
            {'Driver Type': driverType},
            {'Driver\'s License Category': driverLicense ? driverLicenseCat : noliccat},
            {'Driver\'s License': driverLicense ? driverLicense : nolic},
            {'County': county},
            {'Sub County': subCounty},
            {'Ward': ward},
            {'Location': location},
        ]}/>
        <RenderAccordion summary="Contact" go={go} details={[
            {'Stage': stage},
            {'Tuk Tuk Number Plate': tuktukPlateNo},
            {'Logbook': logbook},
            // {'Sacco/Cooperative': sacco},
            {'Tuk Tuk Owner\'s Name': tuktukOwner},
            {'Tuk Tuk Owner\'s Phone Number': tuktukOwnerPhoneNumber},
            {'Driver Phone': currentUser.phoneNumber},
        ]}/>
        <Button color="primary" variant='contained' style={{marginTop: '1.5rem'}} onClick={handleSubmit}>
            Submit
        </Button>
    </Container>
    </div>
    </>
  );
};

export const RenderAccordion = ({ summary, details, go}) => (
    <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon/>}>
            {summary}
        </AccordionSummary>
        <AccordionDetail>
            <div>
                {details.map((data, index) => {
                    const objKey = Object.keys(data)[0];
                    const objValue = data[Object.keys(data)[0]];
                    return <ListItemText key={index}>
                        {`${objKey}: ${objValue}`}
                    </ListItemText>
                })}
                <IconButton color="primary" component="span" onClick={()=> go(`${summary.toLowerCase()}`)}><EditIcon/></IconButton>
            </div>
        </AccordionDetail>
    </Accordion>
)
