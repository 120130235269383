import React, { useState } from 'react';
import tukonlogo from '../../../assets/tukonlogo2.jpeg';
import {FaBars, FaTimes} from 'react-icons/fa';
import {Link} from 'react-router-dom';

const AppBar = () => {
  const [nav, setNav] = useState(false);

  const links = [
    {
      id: 1,
      link: 'home',
      navto: '/home'
    },
    {
      id: 2,
      link: 'services',
      navto: '/home/services'
    },
    {
      id: 3,
      link: 'transactions',
      navto: '/home/transactions'
    },
    {
      id: 4,
      link: 'settings',
      navto: '/home/settings'
    }
  ];

  return (
    <div className='flex justify-between items-center w-full h-20 px-4 text-white bg-white shadow-md fixed'>
        <div onClick={()=> setNav(!nav)} className='cursor-pointer pr-4 z-10 text-gray-500 md:hidden'>
            {nav ? <FaTimes size={30} /> : <FaBars size={30} />}
        </div>
        <div>
            <img src={tukonlogo} alt="TukON" width={120} height={70}/>
        </div>

        {nav && (
            <>
                <ul className='flex flex-col justify-start items-left absolute top-20 left-0 w-200 h-screen bg-white green-text'>
                {links.map(({id, link, navto}) => (
                    <li key={id} className='px-4 cursor-pointer capitalize py-6 text-xl'>
                        <Link onClick={() => setNav(!nav)} to={navto} >{link}</Link>
                    </li>
                ))}
                </ul>
            </> 
        )}
    </div>
  )
}

export default AppBar