import React, { useContext, useState } from 'react';
import { Container, TextField, Button, InputLabel, MenuItem, Select, FormControl, Radio, RadioGroup, FormLabel,FormControlLabel } from '@material-ui/core';
import { AuthContext } from '../../context/AuthContext';
import { Checkbox } from '@mui/material';
import { Navigate } from 'react-router-dom';


export const Names = ({formData, setForm, navigation}) => {

  const {currentUser} = useContext(AuthContext);

  // console.log(currentUser.uid);

  let {driver, owner, group, groupname, driverfirstName, driverlastName, nationalID, driverGender, driverYearOfBirth} = formData;

  const [checked, setChecked] = React.useState(true);

  const [validerror, setValidError] = useState("");

  const groupregs = [
    {
      id:1,
      name: 'Sacco'
    },
    {
      id:2,
      name:'Community Based Organization'
    },
    {
      id:3,
      name:'Self Help Group'
    },
    {
      id:4,
      name:'Transport Company'
    },
    {
      id:5,
      name:'Association'
    }
  ];

  const validate = () => {
    if (driverfirstName === "" || driverlastName === "" || driverGender === "" || driverYearOfBirth === "" || group === "" || groupname === "" || nationalID === "") {
      setValidError("Please fill in all the required fields");
    }
    else {
      navigation.next();
    }
  }

  return (
    <>
    <br></br>
    {/* <br></br> */}
    <div className='mt-8'>
    <Container maxWidth="xs">
        <h3>Step 1/3</h3>
        <p className='mt-3'>All fields marked <span className='text-red-500 font-bold'>*</span> are mandatory</p>
        <TextField
         label="First Name"
         name="driverfirstName"
         value={driverfirstName}
         onChange={setForm}
         required={true}
         margin="normal" type='text' variant='outlined' autoComplete='off' fullWidth
        />
        <TextField
         label="Last Name"
         name="driverlastName"
         onChange={setForm}
         required={true}
         value={driverlastName} margin="normal" variant='outlined' autoComplete='off' fullWidth
        />
        <TextField
         label="National ID"
         name="nationalID"
         onChange={setForm}
         required={true}
         inputProps={{ maxLength:8 }}
         value={nationalID} margin="normal" variant='outlined' autoComplete='off' fullWidth
        />
        <br></br><br></br>
        <FormControl fullWidth>
          <FormLabel id="demo-controlled-radio-buttons-group">Gender*</FormLabel>
          <RadioGroup
            row
            aria-labelledby="demo-controlled-radio-buttons-group"
            name="driverGender"  //removed - controlled-radio-buttons-group 
            value={driverGender}  
            onChange={setForm}
            required={true}
          >
            <FormControlLabel value="Male" control={<Radio />} label="Male" style={{marginLeft: "1px"}}/>
            <FormControlLabel value="Female" control={<Radio />} label="Female" />
          </RadioGroup>
        </FormControl>
        <br></br>
        <label htmlFor="driverYearOfBirth" style={{marginTop: '1.5rem'}}>Date of Birth*:</label>
        <input type="date" id="birthday" name="driverYearOfBirth" value={driverYearOfBirth} onChange={setForm}/>
        <br></br><br></br>
        {/* <h1>Please specify if you are a driver or tuk tuk owner</h1> */}
        {/* <Checkbox
          // checked={checked}
          value={driver}
          onChange={setForm}
          // inputProps={{ 'aria-label': 'controlled' }}
        />
        <Checkbox
          // checked={checked}
          onChange={setForm}
          inputProps={{ 'aria-label': 'controlled' }}
        /> */}
        <label>Please specify the group your are registering under:</label>
        <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label" className='bold-dark-text'>Registration Group*:</InputLabel>
            <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={group}
                label="Group"
                name="group"
                onChange={setForm}
                required={true}
            >
                {groupregs.map((groupreg) => (
                <MenuItem
                key={groupreg.id}
                value={groupreg.name}
                >{groupreg.name}</MenuItem>))}
            </Select>
        </FormControl><br></br><br></br>
        <label htmlFor="sacco" className='mt-3'>
            Please specify the full names of the group:
        </label>
        <TextField
            label="Group:"
            name="groupname"
            value={groupname}
            onChange={setForm}
            margin="normal" variant='outlined' autoComplete='off' fullWidth
        />
        {validerror && <p id='validation-error'>{validerror}</p>}
        <Button variant="contained" fullWidth color="primary" style={{marginTop:"1rem", marginBottom:"5rem", backgroundColor:"#82ad37"}}
        onClick={validate}
        >Next</Button>
    </Container>
    </div>
    </>
  )
}
