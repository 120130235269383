import React from 'react';
import bills from "../../assets/services/bill.png";
import loans from "../../assets/services/send-money.png";
import payments from "../../assets/services/credit-card.png";

const ListTransactions = () => {
    const homes =[
        {
            id: 1,
            src: loans,
            title: 'Loans',
            style: 'shadow-orange-500'
        },
        {
            id: 2,
            src: payments,
            title: 'Payments',
            style: 'shadow-blue-500'
        },
        {
            id: 3,
            src: bills,
            title: 'Bills',
            style: 'shadow-blue-500'
        },
    ];
  return (
    <div className='max-w-screen-lg p-4 mx-auto flex flex-col justify-center w-full h-full lg:ml-60'>
    <div className='w-full grid grid-cols-2 sm:grid-cols-4 gap-8 text-center py-8  sm:px-0'>
        {
            homes.map(({id, src, title, style})=> (
                <div key={id} className={`shadow-md duration-500 py-2 rounded-xl ${style}`}>
                    <img src={src} alt="" className='md:w-60 mx-auto w-24'/>
                    <p className='mt-4'>{title}</p>
                </div>
            ))
        }
    </div>
    </div>
  )
}

export default ListTransactions