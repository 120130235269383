import React from 'react';
import {Route, Router, Routes } from 'react-router-dom';
import rides from "../../../assets/services/rides.png";
import cargo from "../../../assets/services/tuktukdelivery.png";
import ambulance from "../../../assets/services/ambulance.png";
import loans from "../../../assets/services/send-money.png";
import payments from "../../../assets/services/credit-card.png";
import bills from "../../../assets/services/bill.png";

const HomeServices = () => {

    const homes =[
        {
            id: 1,
            src: rides,
            title: 'Rides',
            style: 'shadow-orange-500'
        },
        {
            id: 2,
            src: cargo,
            title: 'Cargo',
            style: 'shadow-blue-500'
        },
        {
            id: 3,
            src: ambulance,
            title: 'Ambulance',
            style: 'shadow-yellow-500'
        },
        {
            id: 4,
            src: loans,
            title: 'Loans',
            style: 'shadow-blue-600'
        },
        {
            id: 5,
            src: payments,
            title: 'Payments',
            style: 'shadow-sky-400'
        },
        {
            id: 6,
            src: bills,
            title: 'Bills',
            style: 'shadow-blue-700'
        },
    ];


    let timeOfDay;
    const date = new Date();
    const hours = date.getHours();
    const styles = {
        fontSize: 35,
    }

    if (hours < 12) {
        timeOfDay = 'Morning';
        styles.color = "#D90000";
    } else if (hours >= 12 && hours < 17) {
        timeOfDay = 'Afternoon';
        styles.color = "#04733F";
    } else {
        timeOfDay = 'Evening';
        styles.color = "#04756F";
    }
  return (
    <div className='max-w-screen-lg p-4 mx-auto flex flex-col justify-center w-full h-full lg:ml-60'>
        <h1 className='text-xl font-bold mt-7 green-text'>Good {timeOfDay} Driver</h1>
    <div className='w-full grid grid-cols-2 sm:grid-cols-4 gap-8 text-center py-8  sm:px-0'>
        {
            homes.map(({id, src, title, style})=> (
                <div key={id} className={`shadow-md duration-500 py-2 rounded-xl ${style}`}>
                    <img src={src} alt="" className='md:w-60 mx-auto w-24'/>
                    <p className='mt-4'>{title}</p>
                </div>
            ))
        }
    </div>
    </div>
  )
}

export default HomeServices