import React from 'react';
import AppBar from '../Home/homecomponents/AppBar';
import NavBar from '../Home/homecomponents/NavBar';
import {Route, Router, Routes } from 'react-router-dom';
import ListServices from './ListServices';

const Services = () => {
  return (
    <div className='flex'>
      <AppBar/>
      <NavBar/>
      <div className='m-12'>
        <div>
        </div>
        <ListServices/>
      </div>
    </div>
  )
}

export default Services