import React from 'react';
import {Link, useNavigate} from 'react-router-dom';
import '../home.scss';

const NavBar = () => {
  const links = [
    {
      id: 1,
      link: 'home',
      navto: '/home'
    },
    {
      id: 2,
      link: 'services',
      navto: '/home/services'
    },
    {
      id: 3,
      link: 'transactions',
      navto: '/home/transactions'
    },
    {
      id: 4,
      link: 'settings',
      navto: '/home/settings'
    }
  ];


  return (
    <div className='hidden md:block fixed'>
      <ul className='flex flex-col justify-start items-left relative top-20 left-0 w-60 h-screen main-bg-color text-white'>
        {links.map(({id, link, navto}) => (
          <li key={id} className='px-4 ml-6 cursor-pointer capitalize font-medium text-white-500 duration-200 py-3'>
            <Link to={navto}>{link}</Link>
          </li>
        ))}
      </ul>
    </div>
  )
}

export default NavBar;