import React, { useState, useContext } from 'react';
import 'react-phone-number-input/style.css';
import PhoneInput from 'react-phone-number-input';
import {Link, useNavigate} from 'react-router-dom';
import { AuthContext } from '../../context/AuthContext';
import logo from '../../assets/tukonlogolarge.jpeg';
import ntsalogo from '../../assets/header.png';
import './signup.scss';
import {
  RecaptchaVerifier,
  signInWithPhoneNumber
} from 'firebase/auth';
import {auth, db} from '../../firebase';
import { collection, doc, getDoc, getDocs, query, where } from 'firebase/firestore';


const Signup = () => {

    const [number, setNumber] = useState("");
    const [otp, setOtp] = useState("");
    const [error, setError] = useState("");
    const [flag, setFlag] = useState(false);
    const [confirmObj, setConfirmObj] = useState("");
    // const {setUpRecaptcha} = useUserAuth();
    const navigate = useNavigate();

    const {dispatch} = useContext(AuthContext);

    function setUpRecaptcha (number) {
      window.recaptchaVerifier = new RecaptchaVerifier('recaptcha-container', {}, auth);
      window.recaptchaVerifier.render()
      // .then((widgetId) => {
        // window.recaptchaWidgetId = widgetId;
      // });
      return signInWithPhoneNumber(auth, number, window.recaptchaVerifier);
    }

    const getOtp = async (e) => {
        e.preventDefault();
        setError("");
        if(number === "" || number === undefined) return setError("Please enter valid phone number");

        // check if user with number already exists
        // const phoneRef = collection(db,"drivers");
        // const q = query(phoneRef, where("driverPhone","==",number));
        // const querySnapshot = await getDocs(q);
        // console.log(querySnapshot)
        // if (querySnapshot != "") {
        //   setError("User already exists");
        // }
        // else {
          try {
            const response = await setUpRecaptcha(number);
            // console.log(response);
            setConfirmObj(response);
            setFlag(true);
          } catch (err) {
              setError(err.message);
          // }
        }
        // console.log(number);
    };

    const verifyOtp = async (e) => {
        e.preventDefault();
        // console.log(otp);
        if(otp === "" || otp === null) return;
        try {
            setError("");
            await confirmObj.confirm(otp)
            .then((result) => {
              const user = result.user;
              dispatch({type:"LOGIN", payload:user});
              navigate("/registration");
            });
            // navigate("/registration");
        } catch (err) {
            setError(err.message);
        }
    }
  return (
    <>
    <div className="login">
      <div className='login-cont'>
      <form onSubmit={getOtp} style={{display: !flag ? "flex" : "none"}}>
        <div className='flex flex-row'>
          <img src={logo} alt="TukON Logo" className='logo'/>
          <img src={ntsalogo} alt="NTSA Logo" className='' style={{width: '150px', height: "120px", paddingTop: "55px"}}/>
        </div>
        <h2>REGISTER</h2>
          <label htmlFor="phonenumber">
            Enter Your Phone Number
          </label>
          <PhoneInput
              defaultCountry='KE'
              value={number}
              onChange={setNumber}
              placeholder="712345678"
              className='phoneinput border focus:outline-none focus:shadow-outline'
              limitMaxLength={true}
              numberInputProps={{maxLength: 9}}
          />
          <p className='text-sm font-normal'>By proceeding, you give consent to receive calls or SMS messages, including by automated means, from TukON and its affiliates to the number provided.</p>
          <button type="submit">NEXT</button>
          {error && <span>{error}</span>}
          {/* <div className="">
              Already a member? <Link to="/">Login</Link>
          </div> */}
          <div id='recaptcha-container'></div>
      </form>

      <form onSubmit={verifyOtp} style={{display: flag ? "flex" : "none"}}>
        <label htmlFor="number">
        Enter OTP code sent to <span style={{color: "#82ad37", fontSize: 16}}>{number}</span>
        </label>
        <input
            type="text"
            placeholder="Enter Code"
            onChange= {(e) => setOtp(e.target.value)}
        />
        <button type='submit'>
            VERIFY
        </button>
      </form>
      <div className='copywright-sec'>
        <p className='font-medium text-xs'>This site is protected by reCAPTCHA and the <a href='https://policies.google.com/privacy?hl=en-US' target='_blank' rel="noreferrer">Google Privacy Policy</a> and <a href='https://policies.google.com/terms' target='_blank' rel="noreferrer">Terms of Service</a> apply.</p>
      </div>
      </div> 
    </div>
    </>
  )
}

export default Signup;