import React from 'react';
import AppBar from '../Home/homecomponents/AppBar';
import NavBar from '../Home/homecomponents/NavBar';
import ListTransactions from './ListTransactions';

const Transactions = () => {
  return (
    <div className='flex'>
      <AppBar/>
      <NavBar/>
      <div className='m-12'>
        <div>
        </div>
        <ListTransactions/>
      </div>
    </div>
  )
}

export default Transactions