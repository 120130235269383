import React, { useContext, useEffect, useState } from 'react';
import AppBar from '../../Home/homecomponents/AppBar';
import NavBar from '../../Home/homecomponents/NavBar';
import placeimage from '../../../assets/person.png';
import { doc, onSnapshot, getDoc } from "firebase/firestore";
import {db} from '../../../firebase';
import { AuthContext } from '../../../context/AuthContext';

const Profilepage = ({inputs}) => {

  const {currentUser} = useContext(AuthContext);

  const [user, setUser] = useState(null);

//   const [data,setData] = useState([]);

//   useEffect(()=> {
//         // LISTEN (REALTIME)
//         const unsub = onSnapshot(doc(db, "drivers", currentUser.uid), (snapShot) => {
//             // console.log("Current data: ", doc.data());
//             let list = [];

//             snapShot.docs.forEach(doc=>{
//                 list.push({id:doc.id, ...doc.data()});
//             });
//             setData(list);
//             console.log(list);
//         },
//         (error) => {
//             console.log(error);
//         }
//         );
//         return () => {unsub();};
//     },[]);


    // const docRef = doc(db, "drivers", currentUser.uid);
    // const docSnap = getDoc(docRef);

    // // if (docSnap.exists()) {
    // console.log("Document data:", docSnap.data());
    // // } else {
    // // doc.data() will be undefined in this case
    // console.log("No such document!");
    // // }


    // const fetchUserInfo = async () => {
    //     const { uid } = currentUser.uid;
    //     // Discard fetch when user ID not defined
    //     if (!uid) return;
    //     // const userRef = db.collection("drivers").doc(uid);
    //     const useRef = doc(db, "drivers", currentUser.uid);
    //     const doc = await getDoc(userRef);
    //     const userData = doc.data();
    //     setUser(userData);
    //     console.log(userData);
    // };

    // Get user on mount
    // useEffect(() => {
    //     fetchUserInfo();
    //     // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, []);

    // if (!user) return null;


  return (
    <div className='flex'>
        <AppBar/>
        <NavBar/>
        <div className='m-12'>
            <div className='max-w-screen-lg p-4 mx-auto flex flex-col justify-center w-full h-full lg:ml-60'>
            <h1 className='text-xl font-bold mt-7 ml-10 green-text'>Profile</h1>
                <div className='py-8 gap-8'>
                    <img src={ user ? user?.image : placeimage} alt="" className='w-40 h-40 upload-image' />
                    <form>
                        {inputs.map((input) => (
                            <div className="formInput" key={input.id}>
                            <label>{input.label}</label>
                            <input
                            id = {input.id}
                            type={input.type} placeholder={user?.driverfirstName} className="shadow appearance-none border rounded py-2 px-3 text-gray-700 focus:outline-none focus:shadow-outline"/>
                            </div>
                        ))}
                    </form>
                </div>
            </div>
        </div>
    </div>
  )
}

export default Profilepage