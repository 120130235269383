import React from 'react';
import rides from "../../assets/services/rides.png";
import cargo from "../../assets/services/tuktukdelivery.png";
import ambulance from "../../assets/services/ambulance.png";
import fooddelivery from "../../assets/services/take-away.png";
import events from "../../assets/services/calendar.png";
import hiring from "../../assets/services/autorickshaw.png";
import beauty from "../../assets/services/cosmetics.png";
import royalhire from "../../assets/services/carriage.png";
import tours from "../../assets/services/tuk\-tuk.png";
import innovation from "../../assets/services/project-management.png";
import saccos from "../../assets/services/cooperation.png";

const ListServices = () => {


    const homes =[
        {
            id: 1,
            src: rides,
            title: 'Rides',
            style: 'shadow-orange-500'
        },
        {
            id: 2,
            src: cargo,
            title: 'Cargo',
            style: 'shadow-blue-500'
        },
        {
            id: 3,
            src: fooddelivery,
            title: 'Food Delivery',
            style: 'shadow-yellow-500'
        },
        {
            id: 4,
            src: ambulance,
            title: 'Ambulance',
            style: 'shadow-blue-600'
        },
        {
            id: 5,
            src: events,
            title: 'Events',
            style: 'shadow-sky-400'
        },
        {
            id: 6,
            src: hiring,
            title: 'Bills',
            style: 'shadow-blue-700'
        },
        {
            id: 7,
            src: beauty,
            title: 'Beauty',
            style: 'shadow-blue-700'
        },
        {
            id: 8,
            src: royalhire,
            title: 'Royal Hire',
            style: 'shadow-blue-700'
        },
        {
            id: 9,
            src: tours,
            title: 'Tours',
            style: 'shadow-blue-700'
        },
        {
            id: 10,
            src: innovation,
            title: 'Innovation',
            style: 'shadow-blue-700'
        },
        {
            id: 11,
            src: saccos,
            title: 'Saccos',
            style: 'shadow-blue-700'
        },
    ];
  return (
    <div className='max-w-screen-lg p-4 mx-auto flex flex-col justify-center w-full h-full lg:ml-60'>
    <div className='w-full grid grid-cols-2 sm:grid-cols-4 gap-8 text-center py-8  sm:px-0'>
        {
            homes.map(({id, src, title, style})=> (
                <div key={id} className={`shadow-md duration-500 py-2 rounded-xl ${style}`}>
                    <img src={src} alt="" className='md:w-60 mx-auto w-24'/>
                    <p className='mt-4'>{title}</p>
                </div>
            ))
        }
    </div>
    </div>
  )
}

export default ListServices