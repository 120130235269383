import './App.css';
import {Route, Router, Routes, Navigate, BrowserRouter } from 'react-router-dom';
import Login from './pages/LogIn/Login';
import Signup from './pages/SignUp/Signup';
import Home from './pages/Home/Home';
import { MultiStepForm } from './pages/Multistep/MultiStepForm';
import Services from './pages/Services/Services';
import Transactions from './pages/Transactions/Transactions';
import Settings from './pages/Settings/Settings';
import { AuthContext } from "./context/AuthContext";
import { useContext } from 'react';
import Profilepage from './pages/Settings/settingscomponents/Profilepage';
import { userInputs } from "./profileFormSource";

function App() {

  const {currentUser} = useContext(AuthContext);

  const RequireAuth = ({children}) => {
    return currentUser ? children : <Navigate to="/"/>;
  };

  return (
        <BrowserRouter>
        <Routes>
          <Route path='/'>
            {/* <Route index element={<Login/>}/> */}
            <Route index element={<Signup/>}/>
            <Route path='home'>
              <Route index element={<RequireAuth><Home/></RequireAuth>}/>
              <Route path='services' element={<RequireAuth><Services/></RequireAuth>}/>
              <Route path='transactions' element={<RequireAuth><Transactions/></RequireAuth>}/>
              <Route path='settings'>
                <Route index element={<RequireAuth><Settings/></RequireAuth>}/>
                <Route path='profile' element={<RequireAuth><Profilepage inputs={userInputs}/></RequireAuth>}/>
              </Route>
            </Route>
            {/* <Route path="/signup" element={<Signup/>}/> */}
            <Route path="/registration" element={<RequireAuth><MultiStepForm/></RequireAuth>}/>
          </Route>
        </Routes>
        </BrowserRouter>
  );
}

export default App;
