import React, { useState } from 'react';
import { Container, TextField, Button,FormControl, InputLabel, Select, MenuItem } from '@material-ui/core';
import PhoneInput from 'react-phone-number-input';
// import { textTransform } from '@mui/system';


export const Contact = ({formData, setForm, navigation}) => {

    const {tuktukPlateNo, logbook, stage, sacco, tuktukOwner, tuktukOwnerPhoneNumber} = formData;

    const [number, setNumber] = useState("");

    const logbooks = [
        {
          id:1,
          name: 'Owner'
        },
        {
          id:2,
          name:'Leased'
        },
        {
            id:3,
            name:'Co-owned'
        },
        {
            id:4,
            name:'Financed'
        },
        {
            id:5,
            name:'Under previous owner'
        }
      ];


    const [validerror, setValidError] = useState("");

    const validate = () => {
      if (tuktukPlateNo === "" || logbook === "" || stage === "" || tuktukOwner === "" || tuktukOwnerPhoneNumber === "" ) {
        setValidError("Please fill in all the required fields");
      }
      else {
        navigation.next();
      }
    }


  return (
    <>
    <br></br>
    {/* <br></br> */}
    {/* <br></br> */}
    {/* <br></br> */}
    <div className='mt-1'>
    <Container maxWidth="xs">
        <h3>Step 3/3</h3>
        <TextField
            label="Stage"
            name="stage"
            value={stage}
            onChange={setForm}
            margin="normal" variant='outlined' autoComplete='off' fullWidth
        />
        <TextField
            label="Tuk Tuk Number Plate*"
            name="tuktukPlateNo"
            value={tuktukPlateNo}
            // onKeyUp={{this.value = this.value.toUpperCase()}}
            // autoCapitalize={true}
            inputProps={{ maxLength:8, style:{ textTransform: "uppercase"} }}
            onChange={setForm}
            margin="normal" variant='outlined' autoComplete='off' fullWidth
        />
        <label htmlFor="sacco" className='mt-3'>
            Please specify ownership of the TukTuk's logbook:
        </label>
        <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label" className='bold-dark-text'>Logbook Ownership:</InputLabel>
            <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={logbook}
                label="Passenger"
                name="logbook"
                onChange={setForm}
                required={true}
            >
                {logbooks.map((logbk) => (
                <MenuItem
                key={logbk.id}
                value={logbk.name}
                >{logbk.name}</MenuItem>))}
            </Select>
        </FormControl><br></br><br></br>
        
         <TextField
            label="Tuk Tuk Owner's Name"
            name="tuktukOwner"
            value={tuktukOwner}
            onChange={setForm}
            margin="normal" variant='outlined' autoComplete='off' fullWidth
        />
        <TextField
            label="Tuk Tuk Owner's Phone Number"
            name="tuktukOwnerPhoneNumber"
            value={tuktukOwnerPhoneNumber}
            onChange={setForm}
            inputProps={{ maxLength:10 }}
            margin="normal" variant='outlined' autoComplete='off' fullWidth
        />
        {validerror && <p id='validation-error'>{validerror}</p>}
        <div style={{marginTop: '1rem', paddingBottom: "5rem"}}>
            <Button color="secondary" variant="contained" style={{marginRight: '1rem'}} onClick={() => navigation.previous()}>Back</Button>
            <Button color="primary" variant="contained" style={{backgroundColor: "#82ad37"}} onClick={validate}>Next</Button>
        </div>
    </Container>
    </div>
    </>
  )
}
