import React from 'react';
import AppBar from '../Home/homecomponents/AppBar';
import NavBar from '../Home/homecomponents/NavBar';
import {Route, Router, Routes } from 'react-router-dom';
import { UserAuthContextProvider } from './../../context/UserAuthContext';
import ListSettings from './ListSettings';

const Settings = () => {
  return (
    <div className='flex'>
    <AppBar/>
    <NavBar/>
    <div className='m-12'>
      <div>
      </div>
      <ListSettings/>
    </div>
  </div>
  )
}

export default Settings