export const userInputs = [
    {
      id: "firstname",
      label: "First Name:",
      type: "text",
      placeholder: "",
    },
    {
      id: "lastname",
      label: "Last Name:",
      type: "text",
      placeholder: "",
    },
    {
        id: "gender",
        label: "Gender:",
        type: "text",
        placeholder: "",
    },
    {
      id: "idnumber",
      label: "ID Number:",
      type: "text",
      placeholder: "",
    },
    {
      id: "phone",
      label: "Phone Number:",
      type: "text",
      placeholder: "",
    },
    {
      id: "drivertype",
      label: "Driver Type:",
      type: "text",
      placeholder: ""
    },
    {
      id: "county",
      label: "County:",
      type: "text",
      placeholder: "",
    },
  ];