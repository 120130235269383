import React from 'react'
import AppBar from './homecomponents/AppBar';
import NavBar from './homecomponents/NavBar';
// import {Route, Router, Routes } from 'react-router-dom';
// import { AuthContext } from '../../context/AuthContext';
import HomeServices from './homecomponents/HomeServices';

const Home = () => {

  // const {currentUser} = useContext(AuthContext);

  // let driverPhone = currentUser.phoneNumber;

  // console.log(driverPhone);

  return (
    <div className='flex'>
      <AppBar/>
      <NavBar/>
      <div className='m-12'>
        <div>
        </div>
        <HomeServices/>
      </div>
    </div>
  )
}

export default Home;